@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nunito';
  src: url('assets/fonts/Nunito-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: 'Minecraftia';
  src: url('assets/fonts/Minecraftia-Regular.ttf');
}

.font-minecraft {
  font-family: 'Minecraftia';
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Nunito';
}

h6, .h6 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 700;
  line-height: 1.2;
}